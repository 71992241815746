import { z } from 'zod'

import { checkAuth, validateResponse } from '../utils'
import { Payment, paymentSchema } from '../types'
import { apiBaseUrl, tokenStorageKey } from '../config'

export async function getNextPayment(): Promise<Omit<Payment, '_id'>> {
  return fetch(apiBaseUrl + '/next-payment', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(paymentSchema.omit({ _id: true })))
}

export async function getPayments(): Promise<Payment[]> {
  return fetch(apiBaseUrl + '/payments', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(z.array(paymentSchema)))
}
