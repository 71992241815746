import {
  ExclaimationCircleIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid'
import * as React from 'react'
import format from 'date-fns/format'

import { classNames, currencyFormat } from '../utils'
import usePaymentsHistory from '../hooks/usePaymentsHistory'
import useNextPayment from '../hooks/useNextPayment'
import { Payment } from '../types'
import Spinner from '../components/Spinner'
import Tooltip from '../components/Tooltip'

function PaymentRow({ payment }: { payment: Omit<Payment, '_id'> }) {
  return (
    <div
      className={classNames(
        payment.status === 'Scheduled' && 'bg-slate-100 py-4 shadow',
        '-mx-4 rounded-xl px-4 '
      )}
    >
      <div className="flex items-baseline justify-between gap-2">
        <span
          className={classNames(
            payment.status === 'Scheduled'
              ? 'text-xl text-slate-700'
              : 'text-base text-slate-500',
            'font-semibold'
          )}
        >
          {currencyFormat.format(payment.amount)}
        </span>

        <div>
          {
            {
              Scheduled: (
                <span className="relative -top-0.5 text-sm text-slate-600">
                  <CalendarDaysIcon className="-mt-0.5 mr-0.5 inline-block w-5 rounded-full text-slate-500" />{' '}
                  To be payed on {format(payment.date, 'PP')}
                </span>
              ),
              done: (
                <Tooltip content={`Payed on ${format(payment.date, 'PPP')}`}>
                  <span className="cursor-help text-sm text-slate-500">
                    <CheckIcon className="-mt-0.5 mr-0.5 inline-block w-4 rounded-full text-green-500" />{' '}
                    {format(payment.date, 'PP')}
                  </span>
                </Tooltip>
              ),
            }[payment.status]
          }
        </div>
      </div>
      {payment.discounts && payment.discounts.length > 0 && (
        <div className="ml-0 mt-4">
          <h2 className="text-[10px] font-semibold uppercase text-slate-600 [letter-spacing:1px]">
            {payment.discounts.length === 1 ? 'Discount' : 'Discounts'}
          </h2>
          <ul className="mt-1 space-y-1">
            {payment.discounts.map((discount) => {
              return (
                <li
                  className="flex gap-1 text-xs text-red-700/80"
                  key={discount.reason}
                >
                  {currencyFormat.format(discount.amount)}
                  <div className="h-3 w-8 border-b border-dotted border-slate-400" />
                  {discount.reason}
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default function PaymentsHistoryPage() {
  const paymentsHistory = usePaymentsHistory()
  const nextPayment = useNextPayment()

  const error = paymentsHistory.error
  const isLoading = paymentsHistory.data === undefined && !error

  return (
    <div className="max-w-2xl">
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>

      {nextPayment.data && (
        <div className="mt-6">
          <p className="mb-2 mt-6 text-sm font-semibold text-slate-500">
            Your next payment
          </p>
          <PaymentRow payment={nextPayment.data} />
        </div>
      )}

      {nextPayment.data === undefined && !nextPayment.error && (
        <Spinner className="mx-auto my-12" />
      )}

      {nextPayment.error && (
        <div className="mt-6 rounded-md bg-red-100 px-4 py-4 text-sm text-red-800">
          <ExclaimationCircleIcon className="-ml-0.5 mr-1 inline-block h-4 w-4 align-middle" />
          <strong className="font-semibold">
            Error finding your next payment
          </strong>
          <p className="mt-2">{nextPayment.error.message}</p>
        </div>
      )}

      {paymentsHistory.data && (
        <>
          <p className="mt-8 text-sm font-semibold text-slate-500">
            Past payments
          </p>
          {paymentsHistory.data.length ? (
            <ol className="mt-2 divide-y">
              {paymentsHistory.data.map((payment) => {
                return (
                  <li key={payment._id} className="py-5">
                    <PaymentRow payment={payment} />
                  </li>
                )
              })}
            </ol>
          ) : (
            <p className="mt-2 text-sm text-slate-500">
              You didn&apos;t receive any payments yet.
            </p>
          )}
        </>
      )}

      {isLoading && <Spinner className="mx-auto my-12" />}

      {error && (
        <div className="mt-6 rounded-md bg-red-100 px-4 py-4 text-sm text-red-800">
          <ExclaimationCircleIcon className="-ml-0.5 mr-1 inline-block h-4 w-4 align-middle" />
          <strong className="font-semibold">Oops...</strong>
          <p className="mt-2">{error.message}</p>
        </div>
      )}
    </div>
  )
}
