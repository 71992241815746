import type { CredentialResponse } from '@react-oauth/google'
import { GoogleLogin } from '@react-oauth/google'
import { Outlet } from 'react-router-dom'
import * as React from 'react'
import decode from 'jwt-decode'

import { googleCredentialSchema } from '../types'
import { getAccessToken } from '../api/authApi'
import { useToasts } from '../components/ToastsProvider'
import useUser from '../hooks/useUser'
import { tokenStorageKey, userStorageKey } from '../config'

export default function LoginPage() {
  const { data, mutate } = useUser()
  const { addToast } = useToasts()

  const handleSuccess = React.useCallback(
    ({ credential }: CredentialResponse) => {
      const googleErrorMsg = 'Google Sign In returned an unexpected response'
      if (!credential) return addToast(googleErrorMsg, { variant: 'danger' })
      getAccessToken(credential)
        .then(({ token }) => {
          const result = googleCredentialSchema.safeParse(decode(credential))
          if (!result.success) {
            console.warn(result.error)
            throw new Error(googleErrorMsg)
          }
          // store in browser to persist session
          window.localStorage.setItem(tokenStorageKey, token)
          window.localStorage.setItem(
            userStorageKey,
            JSON.stringify({
              avatarUrl: result.data.picture,
              fullName: result.data.name,
              email: result.data.email,
            })
          )
          // update swr cache to hide login page
          mutate()
        })
        .catch((err) => addToast(err.message, { variant: 'danger' }))
    },
    [addToast, mutate]
  )

  // render nothing until we know the user state for certain
  if (typeof data === 'undefined') return null

  // if user is already logged in, render the inner route
  if (data) return <Outlet />

  return (
    <div className="flex h-screen flex-col items-center justify-center px-3 pb-56 sm:bg-slate-50">
      <main className="shrink-1 mx-auto rounded-lg text-center sm:bg-white sm:p-12 sm:shadow">
        <img
          width="234"
          height="32"
          className="mb-12 inline-block"
          alt="Southteams"
          // TODO: replace with SVG version
          src={process.env.PUBLIC_URL + '/images/logo-navbar.png'}
        />
        <h1 className="text-2xl text-slate-600 sm:text-3xl">Hey there!</h1>
        <p className="mt-4 text-base text-gray-600">
          Welcome to your Southteams Portal
        </p>

        <div className="mt-6 flex items-center justify-center">
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={() => {
              addToast('Oops... something went wrong. Please, try again.', {
                variant: 'danger',
              })
            }}
            locale="en_US"
          />
        </div>
      </main>
    </div>
  )
}
