import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { getPayments } from '../api/paymentsApi'
import { Payment } from '../types'

export default function usePaymentsHistory(options?: SWRConfiguration) {
  return useSWR<Payment[], Error>(
    'payments',
    () =>
      getPayments().then((d) => d.sort((a, b) => (a.date > b.date ? -1 : 1))),
    options
  )
}
