import {
  ChevronDownIcon,
  XMarkIcon,
  Bars3Icon,
} from '@heroicons/react/24/outline'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import type { NavLinkProps } from 'react-router-dom'
import { NavLink, Outlet } from 'react-router-dom'
import * as React from 'react'

import { classNames, signOut } from '../utils'
import useActiveContract from '../hooks/useActiveContract'
import NoContractError from './NoContractError'
import useUser from '../hooks/useUser'

const navigation = [
  { name: 'Dashboard', to: '/', current: false },
  { name: 'Payments', to: '/payments', current: false },
  { name: 'Time-off', to: '/timeoff', current: false },
  { name: 'Documents', to: '/documents', current: false },
  { name: 'Refer a friend', to: '/referrals', current: false },
]

const userNavigation = [{ name: 'Profile', to: '/profile' }]

const WrappedNavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  function (props, ref) {
    return (
      <NavLink
        {...props}
        className={({ isActive }) =>
          classNames(
            isActive
              ? 'bg-slate-200 text-sky-700'
              : 'text-slate-500 hover:text-sky-600',
            'block rounded-md px-3 py-2 text-base font-medium outline-none focus-visible:ring-2'
          )
        }
        ref={ref}
      />
    )
  }
)

WrappedNavLink.displayName = 'WrappedNavLink'

export default function Layout() {
  const { data } = useUser()

  // An active contract is required to use this site!
  const { isLoading, error } = useActiveContract()
  if (isLoading && !error) return null
  if (error?.status === 404) return <NoContractError />

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-slate-100 md:bg-transparent">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between gap-8">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + '/images/logo-navbar.png'}
                    width="234"
                    height="32"
                    className="block w-full max-w-[200px] flex-shrink-0"
                    alt="Southteams"
                  />
                </div>

                {/* Profile dropdown */}
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="group flex max-w-xs items-center rounded-md py-1 text-sm outline-none hover:bg-slate-100 focus-visible:ring-2 aria-expanded:bg-slate-100">
                          <div className="flex items-center px-2 py-1">
                            <span className="sr-only">Open user menu</span>
                            <div className="flex-shrink-0">
                              {data ? (
                                <img
                                  referrerPolicy="no-referrer"
                                  className="h-6 w-6 rounded-full"
                                  src={data.avatarUrl}
                                  alt=""
                                />
                              ) : (
                                <div className="h-6 w-6 animate-pulse rounded-full bg-slate-200" />
                              )}
                            </div>
                            {data ? (
                              <div className="ml-2 text-base font-medium leading-none text-gray-600 group-hover:text-sky-700">
                                {data.fullName}
                              </div>
                            ) : (
                              <div className="ml-2 h-4 w-24 rounded-full bg-slate-200 text-base leading-none" />
                            )}

                            <ChevronDownIcon
                              className="ml-1 block h-4 w-4 text-slate-400 group-hover:text-sky-700 "
                              aria-hidden="true"
                            />
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <NavLink
                                  to={item.to}
                                  className={() =>
                                    classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )
                                  }
                                >
                                  {item.name}
                                </NavLink>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block w-full px-4 py-2 text-left text-sm text-gray-700'
                                )}
                                onClick={signOut}
                                type="button"
                              >
                                Log out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>

                {/* Mobile menu button */}
                <div className="-mr-2 flex md:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-slate-100 p-2 text-gray-500 outline-none hover:bg-white hover:text-sky-500 focus-visible:ring-2">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as={WrappedNavLink}
                    to={item.to}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-slate-300 pb-3 pt-4 shadow">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    {data ? (
                      <img
                        referrerPolicy="no-referrer"
                        className="h-10 w-10 rounded-full"
                        src={data.avatarUrl}
                        alt=""
                      />
                    ) : (
                      <div className="h-10 w-10 animate-pulse rounded-full bg-slate-200" />
                    )}
                  </div>
                  <div className="ml-3">
                    {data ? (
                      <div className="text-base font-medium leading-none text-gray-600">
                        {data.fullName}
                      </div>
                    ) : (
                      <div className="h-4 w-28 rounded-full bg-slate-200 text-base leading-none" />
                    )}
                    {data ? (
                      <div className="mt-1 text-sm font-medium leading-none text-gray-400">
                        {data.email}
                      </div>
                    ) : (
                      <div className="mt-1 h-[14px] w-44 rounded-full bg-slate-200 text-sm leading-none" />
                    )}
                  </div>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={WrappedNavLink}
                      to={item.to}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                  <button
                    className="block w-full rounded-md px-3 py-2 text-left text-base font-medium text-slate-500 outline-none hover:text-sky-600 focus-visible:ring-2"
                    onClick={signOut}
                    type="button"
                  >
                    Log out
                  </button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="mx-auto mt-4 max-w-7xl px-6 md:flex md:gap-3 lg:gap-8 lg:px-8 xl:gap-24">
        <div className="hidden w-40 shrink-0 md:block">
          <ul>
            {navigation.map((item) => (
              <li key={item.name}>
                <NavLink
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? 'text-sky-600'
                        : 'text-slate-500 hover:bg-slate-50 hover:text-sky-700',
                      'my-1 -ml-3 block rounded-md px-3 py-2 text-lg font-normal outline-none focus-visible:ring-2'
                    )
                  }
                  to={item.to}
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        <div className="grow">
          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  )
}
