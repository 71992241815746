if (!process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID) {
  throw new Error(
    'REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID environment variable must be defined'
  )
}

export const googleSignInClientId =
  process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID

export const apiBaseUrl =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/v1/contractor'

export const useMockedApi = process.env.REACT_APP_USE_MOCKED_API === 'true'

export const authApiBaseUrl =
  process.env.REACT_APP_AUTH_API_BASE_URL || 'http://localhost:8080/v1/auth-com'

export const southteamsEmailAddress = 'admin@southteams.com'

// Maximum date range allowed in a single PTO request (in days)
export const MAX_PTO_REQUEST_RANGE = 30

// Maximum amount of days in a single request
export const MAX_PTO_DAYS_PER_REQUEST = 10

export const tokenStorageKey = 'me-token'

export const userStorageKey = 'me-user'
